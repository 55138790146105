import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import colors from "common/graphs/colors";
import colorsDark from "common/graphs/colorsDark";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import TargetInput from "common/TargetInput";
import { expressTarget, geDateWithOffset } from "common/api";
import { TIMEWINDOW_MAX_MAP } from "common/constants";
import { useTimeOffset } from "common/hooks/useTimeOffset";
import Switch from "@mui/material/Switch";
import Chart from "./Chart";
import { blockingWait } from "common/utils";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const ContextMenuWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;

  .targetInput .input-group {
    margin-bottom: 0px;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-wrap: wrap;

    .targetInput {
      width: 100%;
    }
  }
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

const SelectsWrapper = styled.div`
  display: flex;
`;

const LiveModeWrapper = styled.div`
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .live-mode-switch.MuiSwitch-root {
    & .Mui-checked {
      color: var(--switch-checked-green);
    }

    & .Mui-checked + .MuiSwitch-track {
      background-color: var(--switch-checked-light-green);
    }
  }
`;

const LabelSwitch = styled.div`
  font-family: AllianceNo2-SemiBold !important;
  font-weight: 100;
`;

const _outputScopeChoices = [
  { value: "ip-addresses", label: "IP-Addr" },
  { value: "subscriber-ids", label: "Subs-Ids" },
];

const _timeWindowChoices = [
  { value: "2", label: "2 min. time span" },
  { value: "5", label: "5 min. time span" },
  { value: "10", label: "10 min. time span" },
];

const ContextMenu = ({
  timeWindow,
  setTimeWindow,
  liveMode,
  setLiveMode,
  output,
  setOutput,
}) => {
  return (
    <ContextMenuWrapper>
      <SelectsWrapper>
        <SelectWrapper>
          <SelectInputWithIcon
            title="Outcome grouping"
            name="output"
            selected={output}
            onChange={({ target }) => setOutput(target.value)}
            options={_outputScopeChoices}
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectInputWithIcon
            title="Time Span"
            name="Time Span"
            icon="crop_16_9"
            selected={timeWindow}
            onChange={({ target }) => setTimeWindow(parseInt(target.value))}
            options={_timeWindowChoices}
          />
        </SelectWrapper>
      </SelectsWrapper>
      <LiveModeWrapper>
        <LabelSwitch>Live mode</LabelSwitch>
        <Switch
          className="live-mode-switch"
          checked={liveMode}
          onChange={(e) => setLiveMode(e.target.checked)}
        />
      </LiveModeWrapper>
    </ContextMenuWrapper>
  );
};

function getFieldsAndMap(
  newFieldNamesSet,
  oldFieldsMap,
  colorsSet,
  refColorIndex,
  parsedResponse,
  time,
  timeWindow
) {
  const { map, fields } = [...newFieldNamesSet].reduce(
    (acc, subscriber) => {
      const entry = oldFieldsMap[subscriber];
      if (!entry) {
        let newColor;
        if (subscriber === "rest") {
          newColor = colorsSet[colors.length - 1];
        } else {
          newColor = colorsSet[refColorIndex.current];
        }
        refColorIndex.current =
          (refColorIndex.current + 1) % (colorsSet.length - 1);
        const { downlink, uplink } = parsedResponse[subscriber];
        const total = Number(downlink) + Number(uplink);
        const newField = {
          name: subscriber,
          label: subscriber,
          color: newColor,
          value: total,
        };
        acc.fields.push(newField);
        acc.map[subscriber] = {
          color: newColor,
          time,
          total,
          totalUp: Number(uplink),
          totalDown: Number(downlink),
        };
      } else {
        const timeFieldNotFound = time.getTime() - entry.time.getTime();
        if (parsedResponse[subscriber]) {
          const { downlink, uplink } = parsedResponse[subscriber];
          const total = Number(downlink) + Number(uplink);
          acc.map[subscriber] = {
            ...entry,
            time,
            total: oldFieldsMap[subscriber].total + total,
            totalUp: oldFieldsMap[subscriber].totalUp + Number(uplink),
            totalDown: oldFieldsMap[subscriber].totalDown + Number(downlink),
          };
          const newField = {
            name: subscriber,
            label: subscriber,
            color: entry.color,
            value: total,
          };
          acc.fields.push(newField);
        } else if (timeFieldNotFound <= TIMEWINDOW_MAX_MAP[timeWindow]) {
          acc.map[subscriber] = entry;
          const newField = {
            name: subscriber,
            label: subscriber,
            color: entry.color,
            value: 0,
          };
          acc.fields.push(newField);
        }
      }
      return acc;
    },
    { map: {}, fields: [] }
  );

  return { map, fields: fields.sort() };
}

const getValue = (item, type) => {
  if (item === null) {
    return null;
  }
  const { time } = item;
  return Object.entries(item).reduce(
    (acc, [key, value]) => {
      if (key !== "time") {
        acc[key] = getChartValue(value[type]);
      }

      return acc;
    },
    { time }
  );
};

function getChartValue(value) {
  return value === "n/a" ? null : Number(value);
}

function getLiveThrougputData({ output }) {
  if (output === "subscriber-ids") {
    return ifCl.run(`show subscriber all rates subscriber-id lines 19`);
  }
  return ifCl.run(`show subscriber all rates lines 19`);
}

function parseThroughputResponse(response) {
  if (!response) {
    return {};
  }
  const [header, ...rows] = response.trim(/\s/).split("\n");
  return rows.reduce(
    (acc, value) => {
      const [field, downlink, uplink] = value?.split(/[ ,]+/);
      const key = field === "Rest" ? "rest" : field;
      acc.series[key] = { downlink, uplink };
      acc.totalDown = acc.totalDown + Number(downlink);
      acc.totalUp = acc.totalUp + Number(uplink);
      return acc;
    },
    { totalDown: 0, totalUp: 0, series: {} }
  );
}

const useTime = (counting) => {
  const [seconds, setSeconds] = useState(0);
  const timeout = useRef();

  useEffect(() => {
    increaseSeconds();
  }, [seconds])

  useEffect(() => {
    if (!counting) {
      clearTimeout(timeout.current);
    } else {
      setSeconds(0);
    }
    return () => clearTimeout(timeout.current);
  }, [counting]);

  function increaseSeconds(){
    if(document.visibilityState === 'visible'){
      timeout.current = setTimeout(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000)
    }else{
      blockingWait(1000)
      setSeconds((seconds) => seconds + 1);
    }
  }

  return [seconds, setSeconds];
};

const SubscriberThroughputLiveReport = () => {
  const [output, setOutput] = useState("ip-addresses");
  const [timeWindow, setTimeWindow] = useState(2);
  const [liveMode, setLiveMode] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const { timeOffset } = useTimeOffset();
  const [subs, setSubs] = useState({ map: {}, fields: [] });
  const colorsSet = login.isTheme("light") ? colors : colorsDark;
  const [mouseEvent, setMouseEvent] = useState();
  const [item, setItem] = useState(null);
  const [initTime, setInitTime] = useState();
  const [seconds, setSeconds] = useTime(liveMode);
  const [total, setTotal] = useState({ totalUp: 0, totalDown: 0 });
  const refColorIndex = useRef(0);

  const doLoad = () => {
    if (!isFetching || !liveMode) {
      return;
    }
    getLiveThrougputData({ output })
      .then((response) => {
        const time = new Date();
        time.setMilliseconds(500);
        time.setHours(time.getHours() + timeOffset);
        const {
          series: parsedResponse,
          totalUp,
          totalDown,
        } = parseThroughputResponse(response);
        setItem({ ...parsedResponse, time });
        setTotal({
          totalUp: totalUp + total.totalUp,
          totalDown: totalDown + total.totalDown,
        });

        const oldFieldsMap = { ...subs.map };
        const newFieldKeys = Object.keys(parsedResponse);
        const newFieldNamesSet = new Set([
          ...newFieldKeys,
          ...Object.keys(oldFieldsMap),
        ]);
        setSubs(
          getFieldsAndMap(
            newFieldNamesSet,
            oldFieldsMap,
            colorsSet,
            refColorIndex,
            parsedResponse,
            time,
            timeWindow
          )
        );
      })
      .catch((error) => showModalError(error));
  };

  const resetData = () => {
    setSubs({ map: {}, fields: [] });
    setItem(null);
    setTotal({ totalUp: 0, totalDown: 0 });
  }

  useEffect(() => {
    setIsFetching(false);
    resetData();
    if (timeOffset !== undefined) {
      setIsFetching(true);
      setInitTime(Date.now());
    }
  }, [timeOffset, output, timeWindow]);

  useEffect(() => {
    if (initTime && liveMode && Date.now() - initTime > 10 * 60 * 1000) {
      setLiveMode(false);
    }
    doLoad();
  }, [seconds]);

  useEffect(() => {
    if (liveMode) {
      resetData();
      setInitTime(Date.now());
    }
  }, [liveMode]);

  const { map, fields } = subs || {};

  const handleMouseEvent = (event) => {
    if (event) {
      setMouseEvent({ ...event });
    } else {
      setMouseEvent(null);
    }
  };

  return (
    <Wrapper>
      <ContextMenu
        timeWindow={timeWindow}
        setTimeWindow={setTimeWindow}
        liveMode={liveMode}
        setLiveMode={setLiveMode}
        output={output}
        setOutput={setOutput}
      />
      <div>
        <Chart
          title={"Downlink"}
          fields={fields}
          fieldsMap={map}
          value={getValue(item, "downlink")}
          timeOffset={timeOffset}
          onMouseEvent={handleMouseEvent}
          mouseEvent={mouseEvent}
          seconds={seconds}
          total={total.totalDown}
          liveMode={liveMode}
          setLiveMode={setLiveMode}
          output={output}
          timeWindow={timeWindow}
        />
        <Chart
          title={"Uplink"}
          fields={fields}
          fieldsMap={map}
          value={getValue(item, "uplink")}
          timeOffset={timeOffset}
          onMouseEvent={handleMouseEvent}
          mouseEvent={mouseEvent}
          seconds={seconds}
          total={total.totalUp}
          liveMode={liveMode}
          setLiveMode={setLiveMode}
          output={output}
          timeWindow={timeWindow}
        />
      </div>
    </Wrapper>
  );
};

export default SubscriberThroughputLiveReport;
