/* globals nv, NO_DATA_AVAILABLE */
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import colors from './colors';
import LegendsList, {LegendsAndGraph, useSelection} from "./Legends";

const WrapperDIV = styled.div`
  display: block;
  &,
  & > svg {
    width: 100%;
    height: 100%;
    min-height: inherit;
    .nv-background rect {
      stroke: var(--chart-inner-border-color, #000);
      stroke-opacity: 0.75;
    }
    .nvd3 .nv-axis path.domain {
      stroke-opacity: 0;
    }
    .nv-background rect {
      pointer-events: all;
    }
  }
  ul {
    margin-bottom: calc(10px - 4px);
  }
`;

const defaultTimeFormat = d3.timeFormat("%Y-%m-%d %H:%M");

const skipIfAllVoid = (items, xField, name, extent = null) => {
  let allVoid = true;
  const account = (value) => {
    if (value === undefined || value === null) {
      return value;
    }
    allVoid = false;
    return extent === null ? value : extent.annotate(value);
  };
  const result = items.map((item) => [item[xField], account(item[name])]);
  return allVoid ? [] : result;
};

const givenExtent = (min = null, max = null) => ({
  annotate: (value) => {
    if (value === null) {
      return value;
    }

    min = min === null || value < min ? value : min;
    max = max === null || value > max ? value : max;
    return value;
  },
  range: () => [min, max],
});
const splitByFields = (
  [xField, ...fields],
  items,
  skipVoid = false,
  range = [null, null]
) => {
  const extent = givenExtent(...range);
  const lanes = fields.map(({ name, label, disabled, color = undefined, area }) => ({
    key: label,
    disabled,
    values:
      skipVoid === true
        ? skipIfAllVoid(items, xField, name, extent)
        : items.map((item) => [item[xField], extent.annotate(item[name])]),
    ...(color === undefined ? {} : { color }),
    area,
  }));
  return [lanes, extent.range()];
};

export const translateCoordsOver = (container) => ({
  out: (event) => {
    return {
      container,
      ...event, //Coordiantes are already relative to container
    };
  },
  reflected: (event) => {
    const { left, top } = container.getBoundingClientRect();
    return {
      ...event,
      left: left + event.mouseX,
      top: top + event.mouseY,
    };
  },
});

const extendDomain = ([min, max], { topGap = 0, bottomGap = 0, bottomValue = 0, yMaxValue,yMinValue }) => [
  yMinValue ? (yMinValue - yMinValue * bottomGap) : (min < bottomValue ? min - min * bottomGap : bottomValue),
  yMaxValue ? (yMaxValue + yMaxValue * topGap) : max + max * topGap
]

const expandLinearScale = (scale, params) => {
  const result = scale.copy();
  const originalDomain = result.domain.bind(result);
  result.domain = (...args) =>
    args.length === 0
      ? originalDomain()
      : originalDomain(extendDomain(...args, params));
  return result;
};

export const mayExpandLinearScale = (scale, params) =>
  params.bottomGap === undefined && params.topGap === undefined && params.yMaxValue === undefined && params.yMinValue === undefined
    ? scale
    : expandLinearScale(scale, params);

export const NVLineChart = ({
  className,
  items,
  xField,
  fields,
  tooltipContentGenerator,
  xAxisFormat = defaultTimeFormat,
  yAxisFormat = null,
  yTooltipFormat = null,
  yAxisUnits = "",
  onHighlight = null,
  highlight = null,
  margin = { top: 4, right: 40, bottom: 30, left: 60 },
  yMaxValue = undefined, //Gap = 0.1,
  yMinValue = undefined,
  yAxisTopGap = undefined,
  yAxisBottomGap = undefined,
  yAxisBottomValue = null,
  noDataMessage = NO_DATA_AVAILABLE,
  skipVoid = false,
  xAxisTicksNumber = 3,
  yAxisTicksNumber = 6,
  tooltipEnabled = true,
  onSeriesInspect = null,
  showLegend = true,
  maxKeyLength = 30,
  fixedYRange,
  onRange = undefined
}) => {
  const [wrapper, canvas, chart] = [useRef(null), useRef(null), useRef(null)];
  const [range, setRange] = useState(0);

  let resizeObserver = null;
  useEffect(() => {
    const target = wrapper.current;

    if(document.visibilityState === 'hidden'){
      return;
    }
    
    nv.addGraph(function () {
      if (chart.current === null) {
        chart.current = nv.models
          .lineChart()
          .margin(margin)
          .x((d) => d[0])
          .y((d) => d[1])
          .duration(0)
          .xScale(d3.scaleTime())
          .yScale(
            mayExpandLinearScale(d3.scaleLinear(), {
              topGap: yAxisTopGap,
              bottomValue: yAxisBottomValue,
              bottomGap: yAxisBottomGap,
            })
          )
          .color(colors)
          .useInteractiveGuideline(true) // This affects tooltip behavior
          .showXAxis(true)
          .showLegend(showLegend) // Show legend
          .noData(noDataMessage);
        chart.current.interactiveLayer.tooltip.enabled(tooltipEnabled);
        chart.current.interactiveLayer.tooltip.hideDelay(0);
        chart.current.interactiveLayer.tooltip.gravity("w"); // 's', 'n', 'w', 'e'
        chart.current.legend.maxKeyLength(maxKeyLength);
        if (tooltipContentGenerator !== undefined) {
          chart.current.interactiveLayer.tooltip.contentGenerator(
            tooltipContentGenerator
          );
        }
        if (yTooltipFormat !== null) {
          chart.current.interactiveLayer.tooltip.valueFormatter(yTooltipFormat);
        }

        chart.current.xAxis.tickFormat(xAxisFormat).ticks(xAxisTicksNumber);
        chart.current.yAxis.axisLabel(yAxisUnits);
        chart.current.yAxis.ticks(yAxisTicksNumber);
        yAxisFormat !== null && chart.current.yAxis.tickFormat(yAxisFormat);
      }
      const [datum, [yMin, yMax]] = splitByFields(
        [xField, ...fields],
        items,
        skipVoid,
        [yAxisBottomValue, 0]
      );
      d3.select(canvas.current).datum(datum);
      const [_yDomainMin, yDomainMax] = chart.current.yAxis.domain();

      const main = d3.select(canvas.current);
      const rangeWidthNew = chart.current.xAxis.range()[1];
      if (rangeWidthNew !== range) {
        setRange(rangeWidthNew);
      }
      main.call(chart.current);
      if (resizeObserver === null) {
        resizeObserver = new ResizeObserver(() => chart.current.update && chart.current.update());
        resizeObserver.observe(target);
      }

      main.select(".nv-background rect").attr("rx", 9);

      if (fixedYRange) {
        chart.current.forceY(fixedYRange);
      }
      
      if(fixedYRange){
        chart.current.forceY(fixedYRange);
      }
      
      const translateCoords = translateCoordsOver(main.node());
      if (onHighlight !== null) {
        chart.current.interactiveLayer.dispatch.on(
          "elementMousemove.name",
          (event) => {
            if (event.synthetic === true) {
              return;
            }
            onHighlight({ synthetic: true, ...translateCoords.out(event) });
          }
        );
        chart.current.interactiveLayer.dispatch.on(
          "elementMouseout.name",
          () => {
            onHighlight(null);
          }
        );
      }
      if (onSeriesInspect !== null) {
        chart.current.dispatch.on('renderEnd', () => {
          const linesArea =main.select(".nv-groups")
          linesArea.on("contextmenu", (event, allSeries) => {
            console.warn('context menu', linesArea, event);
            event.preventDefault();
            if (event.target.tagName !== 'path') {
              return;
            }
            const [data] = d3.select(event.target).data();
            const {series} = data.at(0)
            return onSeriesInspect(allSeries[series].key);
          });

          const legends = main.selectAll('.nv-legendWrap .nv-series')
          legends.on("contextmenu", (event) => {
            event.preventDefault();
            const [series] = d3.select(event.target).data();
            return onSeriesInspect(series.key);
          });
        })
      }
      return chart.current;
    });

    return () => {
      resizeObserver && resizeObserver.unobserve(target);
    };
  }, [wrapper, canvas, items, fields]);

  useEffect(() => {
    givenChart(chart).imitateHighlight(highlight);
  }, [highlight, chart]);

  useEffect(() => {
    if(!chart.current){
      return;
    }

    chart.current.yScale(
      mayExpandLinearScale(d3.scaleLinear(), {
        topGap: yAxisTopGap,
        bottomValue: yAxisBottomValue,
        bottomGap: yAxisBottomGap,
        yMaxValue,
        yMinValue
      })
    )

    chart.current.update()

  }, [yMaxValue, yMinValue]);

  useEffect(() => {
    if(onRange){
      onRange(range);
    }
  }, [range]);

  return (
    <WrapperDIV ref={wrapper} className={className}>
      <svg ref={canvas}></svg>
    </WrapperDIV>
  );
};

const doesNothing = () => {};

const applyMargin = ({ top, left }, margin) => ({
  top: top + margin.top,
  left: left + margin.left,
});

const getTooltipNode = (chart) => {
  try {
    return chart.current.interactiveLayer.tooltip._node();
  } catch (error) {
    //console.warn(error);
    return null;
  }
};

const placeTooltipOnPosition = (chart, position, margin) => {
  const tooltipNode = getTooltipNode(chart);
  if (tooltipNode === null) {
    return false;
  }
  tooltipNode.style.visibility = "hidden";
  const correctTooltipPosition = () => {
    const corrected = applyMargin(position, margin);
    const offset = calcGravityOffset(
      chart.current.interactiveLayer.tooltip,
      corrected
    );
    const left = corrected.left + offset.left;
    const top = corrected.top + offset.top;
    tooltipNode.style.visibility = top > 51 ? "visible" : "hidden";
    tooltipNode.style.transform = `translate(${Math.round(
      left
    )}px, ${Math.round(top)}px)`;
  };
  setTimeout(correctTooltipPosition, 25);
};

const givenChart = (chart) => {
  if (chart.current === null) {
    return { imitateHighlight: doesNothing };
  }
  const canvas = chart.current.container;
  const imitateHighlight = (highlight) => {
    if (highlight === null) {
      chart.current.interactiveLayer.dispatch.elementMouseout({
        mouseX: 0,
        mouseY: 0,
      });
      chart.current.interactiveLayer.tooltip.hidden(true);
    } else {
      chart.current.interactiveLayer.tooltip.hidden(false);
      const translateCoords = translateCoordsOver(canvas);
      chart.current.interactiveLayer.dispatch.elementMousemove(highlight);
      const position = translateCoords.reflected(highlight);
      placeTooltipOnPosition(chart, position, chart.current.margin());
    }
  };
  return { imitateHighlight };
};

const NVLineChartWithLegends = ({ fields, onClick, className, previousLegends=[], onSeriesInspect=undefined, showLegend, ...rest }) => {
  const [selection, doToggle] = useSelection(fields);
  const shouldHideLegend = showLegend === false;

  return (
    <LegendsAndGraph className={className}>
      {!shouldHideLegend && 
        <LegendsList items={selection}  
          doToggle={doToggle} previous={previousLegends}
          onLabelClick={onClick} onLabelInspect={onSeriesInspect} />
       }
      <NVLineChart 
        fields={selection} {...rest} showLegend={false} />
    </LegendsAndGraph>
  );
};

export default NVLineChartWithLegends;

let gravity = "w",
  distance = 20;
const calcGravityOffset = function (tooltip, pos) {
  var height = tooltip.node().offsetHeight,
    width = tooltip.node().offsetWidth,
    clientWidth = document.documentElement.clientWidth, // Don't want scrollbars.
    clientHeight = document.documentElement.clientHeight, // Don't want scrollbars.
    left,
    top,
    tmp;
  // calculate position based on gravity
  switch (gravity) {
    case "e":
      left = -width - distance;
      top = -(height / 2);
      if (pos.left + left < 0) left = distance;
      if ((tmp = pos.top + top) < 0) top -= tmp;
      // if((tmp = pos.top + top + height) > clientHeight) top -= tmp - clientHeight;
      break;
    case "w":
      left = distance;
      top = -(height / 2);
      if (pos.left + left + width > clientWidth) left = -width - distance;
      if ((tmp = pos.top + top) < 0) top -= tmp;
      // if ((tmp = pos.top + top + height) > clientHeight) top -= tmp - clientHeight;
      break;
    case "n":
      left = -(width / 2) - 5; // - 5 is an approximation of the mouse's height.
      top = distance;
      if (pos.top + top + height > clientHeight) top = -height - distance;
      if ((tmp = pos.left + left) < 0) left -= tmp;
      //if ((tmp = pos.left + left + width) > clientWidth) left -= tmp - clientWidth;
      break;
    case "s":
      left = -(width / 2);
      top = -height - distance;
      if (pos.top + top < 0) top = distance;
      if ((tmp = pos.left + left) < 0) left -= tmp;
      //if ((tmp = pos.left + left + width) > clientWidth) left -= tmp - clientWidth;
      break;
    case "center":
      left = -(width / 2);
      top = -(height / 2);
      break;
    default:
      left = 0;
      top = 0;
      break;
  }

  return { left: left, top: top };
};
/*
        if (limits.isZero) {
          console.log('FORCING Y-SCALE');
          chart.forceY([0,1]);
        } else {
          chart.forceY([0, null]);
        }

        if (!limits.isZero && that.threshold &&
          that.threshold < (limits.max * ULTP) && that.threshold > (limits.max * 0.90)) {
          yMax = limits.max * ULTP;
        } else {
          yMax = limits.max;
        }

        // NVD3 workaround
        if (that.threshold) {
          chart.yDomain([0, yMax]);
        }

        chart.yAxis
          //.scale(d3.scale.linear().domain([0, yMax]))
          .axisLabel(that.units)
          .ticks(6)
          .showMaxMin(false)
          .tickFormat(function(d) {
            if (d == 0) {
              return d3.format('.0f')(d);
            } else if (d >= 10.0) {
              return d3.format('.0f')(d);
            } else if (d >= 1.0) {
              return d3.format('.1f')(d);
            } else {
              return d3.format('.2f')(d);
            }
          })
          ;

        d3.select(svgSelector)
          .datum(that.data)
          .call(chart)
          ;

        d3.select(that.jqSelector).select(".nv-lineChart")
          .on("mouseleave", function() {
            that.tooltipMgr.onMouseLeave(chart);
          })
          ;

        nv.utils.windowResize(chart.update);

*/



