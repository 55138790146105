import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Request from "common/Request";
import * as d3 from "d3";
import {
  parseTrafficCongestion,
  parseLatencyReduction,
  parseRetransmissionsReduction,
} from "./api";
import {
  parseStatsLatencyData
} from "views/Statistics/System//StatsLatency/api";

import {
  parseStatsRtxData
} from "views/Statistics/System//StatsRtx/api";
import RangeSliderInput from "common/RangeSliderInput";
import NVLineChart from "common/graphs/NVLineChart";
import ActionsContext from "common/ActionsContext";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import {hoursScopeChoices} from "common/constants";
import {formats} from "common/graphs/NVStackedAreaChart";

const SelectWrapper = styled.div`
  width: 150px;
  margin-bottom: 15px;
  
  @media screen and (min-width: 450px) {
    & {
      position: absolute;
      top: 17px;
      right: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
  }
`

const TileContainer = styled.div`
  min-height: 6cm;
  display: flex;
  flex-direction: column;
  & > h4 {
    text-align: center;
    margin: 20px 0 0 0;
  }
  & > .graph {
    flex: 1 1 100%;
    margin: 0;
    .nv-y.nv-axis .nv-axisMaxMin {
      display: none;
    }

    .legends{
      ul {
          margin-top: 5px;
          margin-bottom: 0;
        }
    }

    @media screen and (min-width: 1270px) {
      .legends{
        position: absolute;
        top: -15px;
        right: 0;

        ul {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  &.spread {
    justify-content: space-between;
    padding: 1em;
    align-content: flex-start;
  }
  &.can-compress {
    min-height: 0;
  }
  .input-group {
    max-width: 20ch;
  }
  &.title-and-graph .title {
    font-family: "AllianceNo2-SemiBold";
    font-weight: 100;
    text-align: left !important;
    color: var(--subtitle-color);
    font-size: var(--chart-title-font-size, 18px);
    text-transform: var(--chart-title-text-transform, capitalize);
    margin: 0;
  }
`;

const asItems = (items) => ({ items });

const oneDefimalWithPercentage = d =>
  (d === undefined || d === null)
    ? undefined
    : `${formats.oneDecimal(d)} %`


const fromCongestion = result => result['congestion'];
const fromLatency = result => result['latency'];
const fromRetransmission = result => result['retransmission'];

const congestionGraphSpecs = [
  {
    title: "Average traffic at high speed, under congestion and with ACM",
    fields: [
      { name: "near", label: "Max-speed-traffic" },
      { name: "congested", label: "Congested-traffic" },
      { name: "acm", label: "ACM" },
    ],
    yAxisUnits: "% of total traffic",
    yTooltipFormat: oneDefimalWithPercentage,
    requestHandling: (request) =>
      request.then(fromCongestion).then(parseTrafficCongestion).then(asItems),
  },
  {
    title: "Latency Over Time",
    fields: [{ name: "Rtt-Access", label: "Latency-ms" }],
    yAxisUnits: "milliseconds",
    yTooltipFormat: undefined,
    requestHandling: (request) =>
      request.then(fromLatency).then(parseStatsLatencyData),
  },
  {
    title: "Average TCP Retransmissions Over Time",
    fields: [{ name: "Rtx%-Access", label: "Retransmissions-%" }],
    yAxisUnits: "retransmissions-%",
    yTooltipFormat: undefined,
    requestHandling: (request) =>
      request.then(fromRetransmission).then(parseStatsRtxData)
  },
  {
    title: "Average reduction in latency with ACM",
    fields: [{ name: "reduction", label: "Reduction-ms" }],
    yAxisUnits: "milliseconds",
    yTooltipFormat: undefined,
    requestHandling: (request) =>
      request.then(fromCongestion).then(parseLatencyReduction).then(asItems),
  },
  {
    title: "Average reduction in retransmissions with ACM",
    fields: [{ name: "reduction", label: "Reduction" }],
    yAxisUnits: "% retransmissions",
    yTooltipFormat: oneDefimalWithPercentage,
    requestHandling: (request) =>
      request.then(fromCongestion).then(parseRetransmissionsReduction).then(asItems),
  },
];

const SingleColumn = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;

const skipTimeOffset = (input) => input.split(" ").splice(0, 5).join(" ");

const requestCurrentTime = () =>
  ifCl
    .run("show system date")
    .then(skipTimeOffset)
    .then((input) => new Date(input))
    .catch(
      (error) =>
        console.warn("failed to parse current time:", error) || new Date()
    );

const substractHours = (date, hours) => {
  date.setHours(date.getHours() - hours);
  return date;
};

const getTimeExtent = (hours) =>
  requestCurrentTime().then((current) => ({
    to: current,
    from: substractHours(new Date(current), hours),
  }));

const alonsideRange = (range, items) =>
  range === null || items.length === 0
    ? items
    : extentToRangeLimits(range, items.filter(isInsideRange(range)));

const extentToRangeLimits = (range, items) => [
  { time: range.from },
  ...items,
  { time: range.to },
];

const CongestionGraphsTiles = ({ request, range }) =>
  <>
    {congestionGraphSpecs.map(
      ({ title, fields, requestHandling, yAxisUnits, yTooltipFormat }) => (
        <TileContainer key={title} className="title-and-graph">
          <h4 className="title">{title}</h4>
          {request === null ? null : (
            <Request during={requestHandling(request)}>
              {({ items }) => (
                <NVLineChart
                  items={alonsideRange(range, items)}
                  xField="time"
                  className="graph"
                  yAxisUnits={yAxisUnits}
                  xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                  yTooltipFormat={yTooltipFormat}
                  fields={fields}
                  yAxisTopGap={0.05}
                  fixedYRange={[0, 1]}
                />
              )}
            </Request>
          )}
        </TileContainer>
      )
    )}
  </>

const loadStatistics = ({lines=10}) =>
  Promise.all([
    ifCl.run(`show statistics congestion lines ${lines}`),
    ifCl.run(`show statistics latency lines ${lines}`),
    ifCl.run(`show statistics retransmission lines ${lines}`),
  ]).then( ([congestion, latency, retransmission]) => ({congestion, latency, retransmission}) ) 

const RangeContext = ({ lines = 10, initial }) => {
  const actions = useContext(ActionsContext);
  const [request, setRequest] = useState(null);
  const [range, setRange] = useState(initial);
  const doLoad = () => {
    setRange(initial);
    setRequest(loadStatistics({lines}));
  }
  useEffect(() => {
    doLoad();
  }, [lines]);
  useEffect(() => actions.recv("do-load", doLoad), []);
  return (
    <>
      <CongestionGraphsTiles request={request} range={range} />
      <TileContainer className="full-width can-compress">
        <RangeSliderInput {...initial} onChange={setRange} />
      </TileContainer>
    </>
  );
};

const linesFromHours = (hours) => hours * (60 / 5);

const HoursContext = ({ hours = 24, children }) => {
  const actions = useContext(ActionsContext);
  const [context, setContext] = useState({
    hours,
    lines: linesFromHours(hours),
  });
  const onHoursChange = (hours) => {
    getTimeExtent(hours).then((range) =>
      setContext({ hours, lines: linesFromHours(hours), initial: range })
    );
  };
  useEffect(() => {
    onHoursChange(hours);
    return actions.recv("do-load", () => onHoursChange(hours));
  }, []);

  const handleSelecetHoursChange = ({target})=> {
    onHoursChange(parseInt(target.value))
  }

  return (
    <>
      <SelectWrapper>
        <SelectInputWithIcon
            title="Date Range"
            name="Date Range"
            icon="date_range"
            selected={context.hours}
            onChange={handleSelecetHoursChange}
            options={hoursScopeChoices}
          />
      </SelectWrapper>
      {context.initial !== undefined ? children({ ...context }) : null}
    </>
  );
};

const CongestionReport = () => (
  <SingleColumn>
    <HoursContext>{(context) => <RangeContext {...context} />}</HoursContext>
  </SingleColumn>
);

export default CongestionReport;
