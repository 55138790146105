import {
  ensure,
  wrapErrors,
  asAString,
  asAInteger,
  beBetween,
  noLongerThan,
  notEmpty,
  maxWildcards,
} from "../Common/composition";

const validatePriority = wrapErrors(
  (error) => `Priority ${error}`,
  ensure(asAInteger(beBetween(-9999, 9999)))
);

const anySubscriberIdCollisionWith = (__id, subscriberId) => (existing) =>
  existing.subscriberId === subscriberId && existing.__id !== __id;

const SUBSCRIBER_ID_MAX_LENGTH = 63;

const validateSubscriberId = wrapErrors(
  (error) => `Subscriber ID ${error}`,
  ensure(
    asAString(
      notEmpty,
      noLongerThan(SUBSCRIBER_ID_MAX_LENGTH),
      maxWildcards
    )
  )
);

const validSubscriberIdItems = (item, list) => {
  const { subscriberId, stored, __id } = item;
  validateSubscriberId(subscriberId);
  if (
    stored !== true &&
    list.find(anySubscriberIdCollisionWith(__id, subscriberId)) !== undefined
  ) {
    throw `Subscriber Id "${subscriberId}" already present.`;
  }
  return item;
};

const subscriberIdComposition = {
  cliType: "subscriber-id",
  typeLabel: "Subscriber ID",
  viewURL: "viewSubscriberIDProfileConfig",
  fields: [
    { name: "name", label: "Profile name", type: "text", canUpdate: false },
    {
      name: "subscriberIds",
      cliName: "id",
      label: "Subscriber-IDs",
      labelSingle: "Subscriber ID",
      importable: true,
      type: "many",
      validate: validSubscriberIdItems,
      canBeEmpty: false,
      fields: [
        {
          name: "subscriberId",
          label: "Subscriber Id",
          type: "text",
          wrapSafe: true,
          validate: validateSubscriberId,
          clarify: "Up to two wildcards '*' are allowed. Any spaces inside the subscriber-id will be replaced by the '_' character."
        },
      ],
    },
    {
      name: "priority",
      label: "Priority",
      type: "text",
      defaultValue: 9999,
      icon: "label_outline",
      validate: validatePriority,
    },
  ],
};

export default subscriberIdComposition;
